/* The Modal (background) */
.modal-tooltip {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999999999999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    left: 0;
}

/* Modal Header */
.modal-header-tooltip {
    padding: 2px 16px;
    background: #015b91;
    color: white;
}

/* Modal Body */
.modal-body-tooltip {
    padding: 2px 16px;
}

/* Modal Footer */
.modal-footer-tooltip {
    padding: 2px 16px;
    background: #015b91;
    color: white;
}

/* Modal Content */
.modal-content-tooltip {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
    left: 5%;
}
