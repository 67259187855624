.preview-container .adaptive-gallery {
    display: block;
    width: 88.75vw !important;
}

.masonry.gutterless {
    display: block;
}

.masonry .module {
    display: block;
    padding: 0;
}
