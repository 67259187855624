.react-page-editable .react-page-cell-insert-new {
    cursor: cell;
    flex: 1 1;
    min-height: 100px;
    transition: all 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    z-index: 1;
    position: relative;
}

.react-page-editable .react-page-cell-insert-new-icon {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.26);
    border-radius: 50%;
    display: flex;
    height: 52px;
    justify-content: center;
    width: 52px;
}
.react-page-editable .react-page-cell-insert-new-icon svg {
    height: 24px;
    fill: #fff;
}

.react-page-editable .react-page-cell-insert-new:hover {
    background: rgba(0, 0, 0, 0.26);
    background: rgba(0, 0, 0, 0.26);
    border: 1px solid hsla(0, 0%, 100%, 0.54);
    border: 1px solid hsla(0, 0%, 100%, 0.54);
}
