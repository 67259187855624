.DashbaordRow {
    background-color: #fff;
    padding: 30px !important;
    margin-left: 15px;
    margin-right: 15px;
}

.boxShadow {
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
        0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
}
